<template>
  <article-page :menu="menu">
    <div class="training-content" id="industry-filters">
      <div class="_subtitle">
        Аналитика по расширенным фильтрам
      </div>
      <alert-message full-info type="success" style="margin-top: 12px;">
        <template #content>
          Наша система имеет обширную базу тендеров, дата публикации которых начинается с 2012 года.
        </template>
      </alert-message>
      <span>
        Аналитика по отрасли поддерживает два режима: аналитика по поисковому шаблону и аналитика по расширенным фильтрам.
      </span>
      <span>
        Для открытия формы с расширенными фильтрами необходимо нажать кнопку "Новый анализ".
        Базовыми фильтрами являются фильтры по отраслям, ключевым словам, регионам и законам (44-фз и 223-фз).
        Расширенный режим позволяет уточнить поиск по таким критериям, как:
      </span>
      <ul>
        <li>Законы;</li>
        <li>Площадки;</li>
        <li>СМП и СОНКО;</li>
        <li>НМЦ (Начальная цена);</li>
        <li>Дата публикации;</li>
        <li>Типы закупок;</li>
        <li>Категории ОКПД;</li>
        <li>Место выполнения работ (оказания услуг, поставки товара);</li>
        <li>Заказчики;</li>
        <li>Участники;</li>
      </ul>
      <alert-message full-info type="warning" style="margin-top: 12px;">
        <template #content>
          Обратите внимание, поиск по дереву отраслей работает корректно только при указании даты публикации тендера не ранее, чем 01.01.2020.
          Мы работаем над расширением классификации отраслей, поэтому пожалуйста, воспользуйтесь иными фильтрами при поиске за более ранний период.
        </template>
      </alert-message>
    </div>
    <div class="training-content" id="industry-templates">
      <div class="_subtitle">
        Аналитика по поисковому шаблону
      </div>
      <span>
        В отличие от поиска, селектор шаблонов в аналитике поддерживает выбор только одного шаблона, в нем нельзя выбрать несколько шаблонов или группу шаблонов.
        Для выбора не нужно нажимать никаких дополнительных кнопок, достаточно поставить галочку напротив выбранного шаблона и селектор закроется автоматически.
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt" @click="openViewer(images, 0)"/>
      <span>
        Выбранный шаблон будет отправлен на первоначальную проверку для того, чтобы убедится, что все фильтры в нем заполнены корректно.
        При возникновении ошибки появится соответствующее уведомление. Для редактирования шаблона нужно нажать кнопку "Расширенный анализ",
        исправить ошибку и нажать кнопку "Анализ". Редактирование шаблона в аналитике никак не повлияет на поисковый шаблон.
        В аналитике не поддерживается редактирование, сохранение или удаление шаблонов.
      </span>
      <alert-message full-info type="success" style="margin-top: 12px;">
        <template #content>
          Обратите внимание, фильтр по дате публикации в аналитике по отраслям <b>обязательный</b>,
          поэтому если в шаблоне он отсутствует, то он будет установлен автоматически, периодом в 1 год с текущей даты.
        </template>
      </alert-message>
      <article-image :src="images[1].src" :alt="images[1].alt" @click="openViewer(images, 1)"/>
    </div>
    <div class="training-content" id="industry-results">
      <div class="_subtitle">
        Возможности аналитики
      </div>
      <span>
        Полный анализ для принятия решения участия в государственных и коммерческих торгах:
      </span>
      <ul>
        <li>Общие объемы тендеров по отрасли</li>
        <li>Сезонность размещения тендеров (по годам, по месяцам)</li>
        <li>Анализ активности конкурентов в тендерах</li>
        <li>Анализ заказчиков по публикациям тендеров</li>
        <li>Сводный анализ тендеров любой отрасли или тематики</li>
        <li>Выгрузка данных для расширенной обработки в Excel</li>
      </ul>
      <article-image :src="images[2].src" :alt="images[2].alt" @click="openViewer(images, 2)"/>
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import { useImageViewer } from "@/use/other/useImageViewer";
import { Tabs } from "@/router/tabs";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import AlertMessage from "@/components/ui/alert/AlertMessage.vue";

export default defineComponent({
  name: "AnalyticsIndustryArticle",
  components: {
    AlertMessage,
    ArticleImage,
    ArticlePage,
  },
  setup() {

    const menu = [
      { id: 'industry-filters', title: 'Аналитика по расширенным фильтрам' },
      { id: 'industry-templates', title: 'Аналитика по поисковому шаблону' },
      { id: 'industry-results', title: 'Возможности аналитики' },
    ]

    const images = [
      { src: 'analytics/industry-templates.png', alt: 'Аналитика по шаблону' },
      { src: 'analytics/industry-warning.png', alt: 'Проверка шаблона на валидность' },
      { src: 'analytics/industry-results.png', alt: 'Результаты аналитики по шаблонам' },
    ]

    const { openViewer } = useImageViewer();

    return {
      menu,
      images,
      Tabs,
      openViewer,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';
</style>
